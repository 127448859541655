import BasePlugin from '../BasePlugin'

export default class ExportInvestmentExpensesBudgetFromRequest extends BasePlugin {
  async execute () {
    let recordId = this.context.getModel().id
    let card = this.context.getCard()
    card.setLoading(true)
    this.context.$http({
      method: 'get',
      url: `${this.context.$config.api}/registryservice/plugins/execute/ExportInvestmentBudgetsFromRequestQuery?requestId=${recordId}`,
    }).then((response) => {
      let address = `${this.context.$config.api}/etleditor/export/36`
      this.context.$http({
        method: 'post',
        url: address,
        responseType: 'arraybuffer',
        data: { 'ids': response.data }
      }).then(function (response2) {
        card.setLoading(false)
        let blob = new Blob([response2.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    })
  }
}
